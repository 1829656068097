import { Close } from '@mui/icons-material';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const AryModal = ({
  open,
  handleClose,
  title,
  noCloseOnOutsideClicked,
  noCloseIcon,
  width,
  height,
  justifyContent,
  alignItems,
  rowSpacing,
  columnSpacing,
  descriptionSpacingToTitle,
  style,
  children
}) => {
  const theme = useTheme();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width || '750px',
    maxHeight: '95vh',
    height: height || 'auto',
    maxWidth: '95vw',
    overflow: 'auto',
    backgroundColor: 'background.paper',
    borderRadius: '4px',
    boxShadow: 24,
    p: '24px',
    ...style
  };

  return (
    <Modal
      open={open}
      onClose={noCloseOnOutsideClicked ? () => {} : handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          handleClose();
        }
      }}
    >
      <Box sx={modalStyle}>
        <Grid
          container
          id="modal-modal-title"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item flexGrow={1}>
            <Typography variant="h3Heavy">{title}</Typography>
          </Grid>
          {!noCloseIcon && (
            <Grid item>
              <IconButton onClick={handleClose} tabIndex={-1}>
                <Close />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          id="modal-modal-description"
          mt={descriptionSpacingToTitle || '16px'}
          rowSpacing={rowSpacing || theme.padding?.gridRowSpacing}
          columnSpacing={columnSpacing || theme.padding?.gridColumnSpacing}
          justifyContent={justifyContent}
          alignItems={alignItems}
        >
          {children}
        </Grid>
      </Box>
    </Modal>
  );
};

export default AryModal;
