import { authService } from 'spec';

// Avoid using arrow functions here because of problems in functional context. (https://stackoverflow.com/questions/12995153/apply-not-working-as-expected)
// Please declare auth object concise as possible.
function defaultCheckAccess(claimNames) {
  return this.claims && claimNames.some((cn) => !!+this.claims[cn]);
}

export async function GetAuthObject() {
  const claims = await authService.getClaims();
  return {
    /* ********* below permissions will append to tailing of the desired permissions *********** */

    // At Least 1 claim be true --> AL1
    // Register --> R
    // Edit --> E
    // Normal Edit --> NE
    // Protected Edit --> PE
    // Register and Edit --> RE
    // Delete --> D
    // Print Information --> PI

    // *************** support claims ******************
    GetManagers: defaultCheckAccess.call({ claims }, ['m.c']),
    ShowCustomerProfile: defaultCheckAccess.call({ claims }, ['t.p.c']),
    Migration: defaultCheckAccess.call({ claims }, ['mig']),
    Ticketing: defaultCheckAccess.call({ claims }, ['tic']),
    UserList: defaultCheckAccess.call({ claims }, ['a.m.u']),
    ChargeSection: defaultCheckAccess.call({ claims }, ['a.m.u'])
    // *************** end support claims ******************
  };
}

export default GetAuthObject;
